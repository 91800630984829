import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery, Link } from 'gatsby';
import { SocialIcons } from 'components/Shared';
import LogoBlue from 'src/images/wordmark-blue.svg';
import { Row, Col, Container, media } from 'styled-bootstrap-grid';
import { getRelevantNode } from 'src/utils/helpers';
import FooterCategoryBlock from './FooterCategoryBlock';
import FooterNewsLetterSubscription from './FooterNewsLetterSubscription';

const BackgroundContainer = styled.div`
  position: relative;

  &:before {
    content: '';
    overflow: hidden;
    width: 100%;
    height: 150px;
    position: absolute;
    top: 190px;
    background: #f4f5fa;
  }

  &:after {
    content: '';
    overflow: hidden;
    width: 100%;
    height: 150px;
    position: absolute;
    bottom: 0;
    top: 340px;
    background: linear-gradient(
      to bottom right,
      #f4f5fa 0%,
      #f4f5fa 50%,
      #fff 50%,
      #fff 100%
    );
  }

  ${media.md`
    &:after {
      content: '';
      width: 100%;
      max-width: calc(100% - 20%);
      height: 40%;
      top: unset;
      position: absolute;
      left: 20%;
      bottom: 0;
      background: linear-gradient(
        to bottom right,
        #fff 0%,
        #fff 50%,
        #f4f5fa 50%,
        #f4f5fa 100%
      );
    }
    
    &:before {
      display: none;
    }
  `}
`;

const FooterContainer = styled(Container)`
  position: relative;
  overflow: hidden;
  align-items: center;
  height: 100%;
  padding: 224px 16px 24px 16px;
  margin-top: -160px;
`;

const FooterContent = styled.div`
  position: relative;
  z-index: 1;
`;

const Logo = styled.img`
  width: 144px;
  margin: 0 0 50px;
`;

const LogoLink = styled.a`
  line-height: 1;
`;

const Copyright = styled.div`
  color: ${({ theme }) => theme.palette.grey[700]};
  font-size: 12px;
  font-weight: 400;
  line-height: 40px;
`;

const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.palette.grey[700]};
  text-decoration: none !important;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 8px;

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.light};
  }
`;

const IconContainer = styled.div`
  display: flex;
  margin-left: -20px;
  margin-bottom: 16px;
`;

const FooterBottom = styled(Row)`
  margin-top: 12px;

  ${media.md`
    margin-top: 45px;
  `}
`;

const Footer = ({ data, pageLang }) => {
  const {
    footer_menu,
    social_links,
    footer_categories,
    newsletter_image,
    newsletter_email_label,
    newsletter_headline_highlight,
    newsletter_headline_post_highlight,
    newsletter_headline_pre_highlight,
    marketing_notice,
    privacy_notice_highlight,
    privacy_notice_pre_highlight,
    privacy_notice_post_highlight,
    newsletter_submit_button_text,
    hubspot_form_id,
    privacy_notice_link,
    newsletter_success_message,
    newsletter_error_message,
  } = getRelevantNode(data.allPrismicFooter, pageLang).data;

  const year = new Date().getFullYear();

  return (
    <BackgroundContainer>
      <FooterContainer>
        <FooterContent>
          <Container>
            <LogoLink>
              <Logo src={LogoBlue} />
            </LogoLink>
            <Row>
              <Col col={12} md={6} lg={7} xsOrder="last" smOrder="last">
                <Row>
                  {footer_categories.map(({ category }, index) => {
                    const { links, title } = category.document.data;
                    return (
                      <Col col={6} lg={4} key={'footer-category-' + index}>
                        <FooterCategoryBlock title={title} links={links} />
                      </Col>
                    );
                  })}
                </Row>
                <FooterBottom>
                  <Col>
                    <IconContainer>
                      <SocialIcons socialLinks={social_links} />
                    </IconContainer>
                    <FooterMenu>
                      {footer_menu.map(({ footer_link, link_label }, index) => {
                        return (
                          <FooterLink
                            key={index + 'FooterLink'}
                            to={`${footer_link.url}`}
                          >
                            {link_label.text}
                          </FooterLink>
                        );
                      })}
                    </FooterMenu>
                    <Copyright>&copy; {year} Seedtrace GmbH</Copyright>
                  </Col>
                </FooterBottom>
              </Col>
              <Col col={12} md={6} lg={5} mdOrder="last">
                {hubspot_form_id?.text && (
                  <FooterNewsLetterSubscription
                    image={newsletter_image}
                    emailLabel={newsletter_email_label}
                    headlineHighlight={newsletter_headline_highlight}
                    headlinePostHighlight={newsletter_headline_post_highlight}
                    headlinePreHighlight={newsletter_headline_pre_highlight}
                    marketingNotice={marketing_notice}
                    privacyNoticeHighlight={privacy_notice_highlight}
                    privacyNoticePreHighlight={privacy_notice_pre_highlight}
                    privacyNoticePostHighlight={privacy_notice_post_highlight}
                    submitButtonText={newsletter_submit_button_text}
                    hubspotFormId={hubspot_form_id}
                    privacyNoticeLink={privacy_notice_link}
                    newsletterSuccessMessage={newsletter_success_message}
                    newsletterErrorMessage={newsletter_error_message}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </FooterContent>
      </FooterContainer>
    </BackgroundContainer>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allPrismicFooter {
          nodes {
            lang
            tags
            type
            id
            data {
              newsletter_image {
                alt
                copyright
                url
                thumbnails
              }
              newsletter_email_label {
                text
              }
              privacy_notice_pre_highlight {
                text
              }
              privacy_notice_highlight {
                text
              }
              privacy_notice_post_highlight {
                text
              }
              marketing_notice {
                text
              }
              newsletter_headline_pre_highlight {
                text
              }
              newsletter_headline_highlight {
                text
              }
              newsletter_headline_post_highlight {
                text
              }
              hubspot_form_id {
                text
              }
              newsletter_submit_button_text {
                text
              }
              privacy_notice_link {
                link_type
                uid
                url
              }
              newsletter_success_message {
                text
              }
              newsletter_error_message {
                text
              }
              footer_menu {
                footer_link {
                  link_type
                  uid
                  url
                }
                link_label {
                  text
                }
              }
              social_links {
                social_network
                social_page {
                  link_type
                  url
                }
              }
              footer_categories {
                title {
                  text
                }
                category {
                  document {
                    ... on PrismicFooterCategory {
                      id
                      lang
                      dataRaw
                      data {
                        title {
                          text
                        }
                        links {
                          label_link {
                            text
                          }
                          link {
                            link_type
                            uid
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Footer data={data} {...props} />}
  />
);
