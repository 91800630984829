import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Container as Grid, Col, Row, media } from 'styled-bootstrap-grid';
import { Link, navigate } from 'gatsby';
import Imgix from 'react-imgix';
import { ChevronDown } from '@styled-icons/feather/ChevronDown';

const SubMenuContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 8px;
  background-color: transparent;
  display: none;
  padding: 32px 0px;
  margin: 0px;
  min-width: 420px;
  max-width: 450px;
  display: block;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
`;

const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  font-weight: 400;
  font-family: Lato;
  font-size: 20px;
  text-align: left;
  line-height: 0.2;
  letter-spacing: 0.05em;
  white-space: nowrap;
  align-self: center;
  color: ${({ theme, isSubItem }) =>
    isSubItem ? theme.palette.primary.main : theme.palette.text.primary};
  width: 100%;
  height: 0px;
  margin: 0px;
  padding: 24px;
  padding-left: 32px;
  cursor: ${({ clickable }) => (clickable ? 'cursor' : 'default')};

  ${media.lg`
    font-family: Lato;
    font-size: 14px;
    text-transform: none;
    margin-left: 16px;
    padding: 0px 8px;
    width: auto;
  `}

  ${media.xxl`
    font-family: Lato;
    font-size: 14px;
    text-transform: none;
    margin-left: 24px;
    padding: 0px 8px;
    width: auto;
  `}
  
  transition: font-weight 0.25s ease-in-out, letter-spacing 0.25s ease-in-out, color 0.25s ease-in-out;
  &.active:hover {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.light};
  }
`;

const LinkContent = styled.div`
  position: relative;
  text-decoration: none;
  font-weight: 400;
  font-family: Lato;
  font-size: 20px;
  text-align: left;
  line-height: 0.2;
  letter-spacing: 0.05em;
  white-space: nowrap;
  align-self: center;
  color: ${({ theme, isSubItem }) =>
    isSubItem ? theme.palette.primary.main : theme.palette.text.primary};
  width: 100%;
  height: 0px;
  margin: 0px;
  padding: 24px;
  padding-left: 32px;
  cursor: default;

  ${media.lg`
    font-family: Lato;
    font-size: 14px;
    text-transform: none;
    margin-left: 16px;
    padding: 0px 8px;
    width: auto;
  `}

  ${media.xxl`
    font-family: Lato;
    font-size: 14px;
    text-transform: none;
    margin-left: 24px;
    padding: 0px 8px;
    width: auto;
  `}
  
  transition: font-weight 0.25s ease-in-out, letter-spacing 0.25s ease-in-out, color 0.25s ease-in-out;
  &.active:hover {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.light};
  }

  ${SubMenuContainer} {
    visibility: collapse;
    opacity: 0;
  }

  &:hover ${SubMenuContainer} {
    visibility: visible;
    opacity: 1;
  }
`;

const NavigationUnderscore = styled.div`
  position: relative;
  bottom: -10px;
  left: 0px;
  border-radius: 15px;
  background-color: white;
  height: 3px;
  width: ${({ active }) => (active ? '50%' : '0%')};
  transition: width 0.25s ease-in-out;
  background-color: ${({ theme, active }) =>
    active ? theme.palette.primary.main : theme.palette.secondary.light};

  ${media.lg`

    ${StyledLink}:hover & {
      width: 50%;
      background-color: ${({ theme }) => theme.palette.secondary.light};
    }
  
    ${StyledLink}.active & {
      width: 50%;
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
      
    ${StyledLink}.active::hover & {
      width: 50%;
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
    
    ${LinkContent}:hover & {
      width: 50%;
      background-color: ${({ theme }) => theme.palette.secondary.light};
    }
  
    ${LinkContent}.active & {
      width: 50%;
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
      
    ${LinkContent}.active::hover & {
      width: 50%;
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
    
  `}
`;

const StyledSubMenu = styled.div`
  position: relative;
  border-radius: 8px;
  padding: 12px 0px;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  display: ${({ showMenu }) => (showMenu ? 'block' : 'block')};
`;

const SubMenuItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-radius: 8px;
  margin: 4px 0px;
  padding: 8px;

  &:hover {
    background-color: ${({ theme }) => `${theme.palette.success.light}`};
    cursor: pointer;
  }
`;

const SubMenuItemIcon = styled(Imgix)`
  height: 95%;
  object-fit: cover;
`;

const SubMenuTextContainer = styled.div`
  margin: 0px 0px 0px 16px;
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SubMenuText = styled.p`
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: Lato;
  font-weight: 600;
  font-size: 12px;
  white-space: normal;
  line-height: 17px;
  padding: 0px;
  text-transform: none;
  margin: 0px;
  text-align: left;
`;

const SubMenu = ({ links, showMenu }) => {
  const handleClick = (link) => {
    navigate(link.url);
  };
  return (
    <SubMenuContainer showMenu={showMenu}>
      <StyledSubMenu>
        <Grid>
          <Row>
            {links.map(({ link, image, text }, index) => {
              return (
                <Col col={6} key={index}>
                  <SubMenuItem onClick={() => handleClick(link)}>
                    <SubMenuItemIcon
                      className="lazyload"
                      src={image?.url}
                      htmlAttributes={{
                        alt: image?.alt,
                      }}
                      sizes="100vw"
                      attributeConfig={{
                        src: 'data-src',
                        srcSet: 'data-srcset',
                        sizes: 'data-sizes',
                      }}
                    />
                    <SubMenuTextContainer>
                      <SubMenuText>{text.text}</SubMenuText>
                    </SubMenuTextContainer>
                  </SubMenuItem>
                </Col>
              );
            })}
          </Row>
        </Grid>
      </StyledSubMenu>
    </SubMenuContainer>
  );
};

const StyledMobileMenu = styled.div`
  padding: 8px 12px 8px 32px;
  margin: 0px;

  ${media.md`
   width: 94.5%;
 `}
`;

const MobileMenuItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const MobileMenuText = styled.p`
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-decoration: none;
  white-space: nowrap;
  padding: 0px;
  margin: 0px;
  color: ${({ theme }) => `${theme.palette.text.primary}`};

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.light};
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scaleY(0.5) translateY(-50%);
  }
  to {
    opacity: 1;
    transform: scaleY(1) translateY(0);
  }
`;

const MobileSubMenu = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin: 0px;
  animation: ${(show) => show && fadeIn} 0.2s ease-in-out;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const MobileSubMenuItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  border-radius: 8px;
  margin: 4px 0px;
  padding: 8px;

  &:hover {
    background-color: ${({ theme }) => `${theme.palette.success.light}`};
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  height: 40px;
  width: 80px;
`;

const MobileSubMenuItemIcon = styled(Imgix)`
  height: 95%;
  object-fit: contain;
`;

const MobileSubMenuTextContainer = styled.div`
  width: 100px;
`;

const StyledChevronDown = styled(ChevronDown)`
  color: ${({ theme }) => theme.palette.primary.main};
  transition: transform 0.25s ease-out, opacity 0.25s ease-out;
  transform: ${({ rotate }) => rotate && 'rotate(-180deg)'};
`;

const SubMenuMobile = ({ open, title, links }) => {
  const [isOpen, setOpen] = useState(open);

  return (
    <StyledMobileMenu>
      <MobileMenuItem onClick={() => setOpen(!isOpen)}>
        <MobileMenuText>{title.text}</MobileMenuText>
        <StyledChevronDown rotate={isOpen ? 1 : 0} size={40} />
      </MobileMenuItem>
      <MobileSubMenu show={isOpen}>
        {links.map(({ link, image, text }, index) => {
          return (
            <MobileSubMenuItem key={index} onClick={() => navigate(link.url)}>
              <IconContainer>
                <MobileSubMenuItemIcon
                  className="lazyload"
                  src={image?.url}
                  sizes="100vw"
                  attributeConfig={{
                    src: 'data-src',
                    srcSet: 'data-srcset',
                    sizes: 'data-sizes',
                  }}
                />
              </IconContainer>
              <MobileSubMenuTextContainer>
                <SubMenuText>{text.text}</SubMenuText>
              </MobileSubMenuTextContainer>
            </MobileSubMenuItem>
          );
        })}
      </MobileSubMenu>
    </StyledMobileMenu>
  );
};

const HeaderLink = ({
  activePathname,
  navLink,
  isMobile,
  title,
  subMenuLinks,
}) => {
  const linkedPageIsActive = subMenuLinks?.links
    ? subMenuLinks?.links.map((link) => link.link.url).includes(activePathname)
    : false;

  if (isMobile && subMenuLinks) {
    return (
      <SubMenuMobile
        open={linkedPageIsActive}
        title={title}
        links={subMenuLinks.links}
      />
    );
  }

  if (navLink?.url) {
    return (
      <StyledLink
        key={navLink?.uid}
        className="nav-link"
        activeClassName="active"
        clickable={1}
        partiallyActive={true}
        to={navLink?.url}
      >
        {title?.text}
        <NavigationUnderscore active={linkedPageIsActive} />
      </StyledLink>
    );
  }

  return (
    <LinkContent>
      {title?.text}
      <NavigationUnderscore active={linkedPageIsActive} />
      {!isMobile && subMenuLinks && <SubMenu links={subMenuLinks.links} />}
    </LinkContent>
  );
};

export default HeaderLink;
