import React, { useEffect, useState } from 'react';
import LogoBlue from 'src/images/wordmark-blue.svg';
import LogoWhite from 'src/images/wordmark-white.svg';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Container, media } from 'styled-bootstrap-grid';
import { PrimaryButton, ReadMoreButton, LanguageSwitch } from '../Shared';
import HeaderLink from './HeaderLink';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useRequestDemo, useTheme } from 'src/hooks';
import Burger from './Burger';
import { getRelevantNode } from '../../utils/helpers';

const Header = styled.div`
  transition: all 0.125s ease-out;
  z-index: 1000;
  font-size: 18px;
  border-bottom: 0px solid rgba(255, 255, 255, 0.5);
  position: fixed;
  width: 100%;
  height: ${({ theme }) => theme.sizes.fixedHeader.height};
  background-color: ${({ transparent }) =>
    transparent ? 'transparent' : '#fff'};
  box-shadow: none;

  ${media.lg`
    height: ${({ theme }) => theme.sizes.fixedHeader.heightLg};
  `}

  ${media.xxl`
    height: ${({ theme }) => theme.sizes.fixedHeader.heightXxl};
  `}

  ${({ active }) =>
    active &&
    `
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1); 
  `}
`;

const NavContainer = styled(Container)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px 16px;

  ${({ wide }) =>
    wide &&
    `
    max-width: 100% !important;
  `}
`;

const NavActions = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;

  ${media.lg`
    justify-content: center;
  `}
`;

const ActionButtons = styled.div`
  display: none;
  position: relative;

  ${media.lg`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `}
`;

const StyledReadMoreButton = styled(ReadMoreButton)`
  margin-left: 24px;
  width: 96px;
`;

const LogoLink = styled(Link)`
  line-height: 1;
  ${({ open }) =>
    open &&
    `
    display: none;
  `}

  ${media.lg`
    display: block
  `};
`;

const NavigationUnderscore = styled.div`
  position: relative;
  bottom: -10px;
  left: 0px;
  border-radius: 15px;
  background-color: white;
  height: 3px;
  width: 0px;
  transition: width 0.25s ease-in-out;
  background-color: ${({ theme }) => theme.palette.secondary.light};

  ${media.lg`
    
    ${LogoLink} & {
      width: 0px;
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
    
    ${LogoLink}.active & {
      width: 50%;
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
    
    ${LogoLink}:hover & {
      width: 50%;
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  `}
`;

const NavLinks = styled.div`
  display: none;

  ${media.lg`
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    flex-grow: 1;
    position: relative;
    background: transparent;
    flex-direction: row;
    justify-content: center;
    height: auto;
    width: auto;
    transform: none;
    z-index: 0;
  `}
`;

const NavLinksMobile = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 48px;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  transform: translateY(-150%);
  background: #fff;
  flex-grow: 1;
  transition: transform 0.25s ease-in-out;
  z-index: -1;
  ${({ open }) =>
    open &&
    `
    transform: translateY(0);
  `}

  ${media.lg`
    display: none;
  `}
`;

export const ActionButtonsMobile = styled.div`
  width: 100%;
  padding: 16px 0px;
  display: block;
  background-color: green;
  ${media.lg`
    display: none;
  `}
`;

const Logo = styled.img`
  width: ${({ theme }) => theme.sizes.headerLogo.width};
  margin: 0;

  ${media.xxl`
    display: block;
    width: ${({ theme }) => theme.sizes.headerLogo.widthXxl};
  `}
`;

const DemoButton = styled(PrimaryButton)`
  position: relative;
  z-index: 1;
  margin: 0 24px;

  ${media.lg`
    margin: 0;
  `}
`;

const HeaderNavigation = ({
  location,
  pageLang,
  altLangs,
  white,
  reduced,
  inverted,
  wide,
}) => {
  const result = useStaticQuery(query);
  const data = getRelevantNode(result.allPrismicPageNavigation, pageLang).data;
  const navLinks = data.nav_links;
  const [didScroll, setDidScroll] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleOpenScheduling } = useRequestDemo();
  const showBackground = didScroll || menuOpen || white;
  const isLanding =
    location?.pathname === '/' || location?.pathname === '/de-de/'
      ? 'active'
      : '';
  useScrollPosition(
    ({ currPos }) => setDidScroll(currPos.y < 0),
    [setDidScroll],
    false,
    false,
    150
  );
  const theme = useTheme();

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [menuOpen]);

  return (
    <Header active={didScroll} transparent={!showBackground}>
      <NavContainer wide={wide}>
        <LogoLink
          open={menuOpen}
          className={isLanding}
          to={pageLang === 'en-us' ? '/' : '/de-de/'}
        >
          <Logo
            src={inverted && !menuOpen && !didScroll ? LogoWhite : LogoBlue}
            alt-Logo
          />
          <NavigationUnderscore />
        </LogoLink>
        {!reduced && (
          <NavActions>
            <NavLinks open={menuOpen}>
              {navLinks.map(({ nav_link, title, sub_menu }, index) => {
                return (
                  <HeaderLink
                    navLink={nav_link}
                    title={title}
                    key={index}
                    activePathname={location.pathname}
                    subMenuLinks={sub_menu?.document?.data}
                    isMobile={false}
                  />
                );
              })}
            </NavLinks>
            <ActionButtons>
              <LanguageSwitch currentLang={pageLang} languages={altLangs} />
              <StyledReadMoreButton
                buttonText={data.login_button_text?.text || 'Login'}
                className="plausible-event-name=Navigate+to+Seedtrace+Platform"
                to={'https://app.seedtrace.org/login'}
              />
              <DemoButton
                onClick={() => handleOpenScheduling()}
                size="small"
                className="plausible-event-name=Book+Demo+Button+Click"
              >
                {data.demo_button_text?.text || 'Demo'}
              </DemoButton>
            </ActionButtons>
            <NavLinksMobile open={menuOpen}>
              <HeaderLink
                navLink={{ url: pageLang === 'en-us' ? '/' : '/de-de/' }}
                title={{ text: 'Home' }}
                isMobile={true}
              />
              {navLinks.map(({ nav_link, title, sub_menu }, index) => {
                return (
                  <HeaderLink
                    navLink={nav_link}
                    title={title}
                    key={index}
                    activePathname={location.pathname}
                    subMenuLinks={sub_menu?.document?.data}
                    isMobile={true}
                  />
                );
              })}
              <LanguageSwitch currentLang={pageLang} languages={altLangs} />
              <DemoButton
                onClick={() => handleOpenScheduling()}
                size="small"
                className="plausible-event-name=Book+Demo+Button+Click"
              >
                {data.demo_button_text?.text || 'Demo'}
              </DemoButton>
            </NavLinksMobile>
            <Burger open={menuOpen} onClick={() => setMenuOpen(!menuOpen)} />
          </NavActions>
        )}
      </NavContainer>
    </Header>
  );
};

export default HeaderNavigation;

const query = graphql`
  query HeaderQuery {
    allPrismicPageNavigation {
      nodes {
        lang
        tags
        data {
          demo_button_text {
            text
          }
          login_button_text {
            text
          }
          nav_links {
            nav_link {
              uid
              target
              url
              link_type
            }
            title {
              text
            }
            sub_menu {
              document {
                ... on PrismicSubnavigation {
                  id
                  lang
                  dataRaw
                  data {
                    links {
                      image {
                        alt
                        copyright
                        url
                        thumbnails
                      }
                      link {
                        id
                        url
                      }
                      text {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
