import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'styled-bootstrap-grid';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 24px;

  ${media.md`
    margin-bottom: 0;
  `}
`;

const Headline = styled.h4`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 12px;
`;

const FooterLink = styled(Link)`
  text-decoration: none !important;
  font-weight: 400;
  font-size: 14px;
  margin: 6px 0;
  color: #000;
  font-family: Lato;

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.light};
  }
`;

const FooterCategoryBlock = ({ title, links }) => {
  return (
    <Container>
      <Headline>{title.text}</Headline>
      {links.map(({ link, label_link }, index) => (
        <FooterLink key={index} to={`${link.url}`}>
          {label_link.text}
        </FooterLink>
      ))}
    </Container>
  );
};

export default FooterCategoryBlock;
