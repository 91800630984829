import React, { Fragment } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { graphql, StaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import Header from './Header';
import shareImage from 'src/images/preview.png';
import { CookieBanner } from 'components/Shared';
import {
  SignUpNewsletterProvider,
  RequestDemoProvider,
  RequestDownloadProvider,
} from 'components/Contexts';

const StyledMain = styled.main`
  position: relative;
  z-index: 1;
  padding-top: ${({ theme }) => theme.sizes.headerOffSet.height};
  display: ${({ flexMain }) => (flexMain ? 'fkex' : 'block')};
  min-height: 100vh;
  overflow: hidden;

  ${media.lg`
    padding-top: ${({ theme }) => theme.sizes.headerOffSet.heightLg};
  `}

  ${media.xxl`
    padding-top: ${({ theme }) => theme.sizes.headerOffSet.heightXxl};
  `}
  ${({ flexMain }) =>
    flexMain &&
    `
    .header-container {
      max-width: 100% !important;
    }
  `}
`;

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteQuery {
        site {
          siteMetadata {
            title
            description
            banner
            url
          }
        }
      }
    `}
    render={(data) => <Layout data={data} {...props} />}
  />
);

const Layout = ({
  data,
  children,
  location,
  pageLang,
  altLangs,
  seo = {},
  whiteHeader,
  invertedHeader,
  reducedHeader,
  className,
  showFooter = true,
  flexMain,
}) => {
  // Define the meta title and description
  const {
    title: defaultTitle,
    description: defaultDescription,
    url,
  } = data.site.siteMetadata;
  let { title, description, image } = seo;
  title = title && `${title} - seedtrace`;

  // Load the Prismic edit button
  if (typeof window !== 'undefined' && window.prismic) {
    window.prismic.setupEditButton();
  }

  return (
    <Fragment>
      <RequestDemoProvider pageLang={pageLang}>
        <SignUpNewsletterProvider>
          <RequestDownloadProvider pageLang={pageLang}>
            <Helmet>
              <meta charSet="utf-8" />
              <html lang="en-us" />
              <title>{title || defaultTitle}</title>
              <meta
                name="description"
                content={description || defaultDescription}
              />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
              />
              <meta name="image" content={image || shareImage} />
              <meta property="og:url" content={url} />
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content={image || shareImage} />
              <script>{`window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`}</script>
            </Helmet>
            <Header
              pageLang={pageLang}
              altLangs={altLangs}
              location={location}
              white={whiteHeader}
              reduced={reducedHeader}
              inverted={invertedHeader}
              wide={flexMain}
            />
            <StyledMain className={className} flexMain={flexMain}>
              {children}
            </StyledMain>
            {showFooter && <Footer pageLang={pageLang} />}
            <CookieBanner pageLang={pageLang} />
          </RequestDownloadProvider>
        </SignUpNewsletterProvider>
      </RequestDemoProvider>
    </Fragment>
  );
};
