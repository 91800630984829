import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Container, media } from 'styled-bootstrap-grid';
import Imgix from 'react-imgix';
import { ErrorMessage, FormControl } from '../Shared/styles';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import LoadingButton from '../Shared/LoadingButton';
import { Link } from 'gatsby';

const NewsletterContainer = styled.div`
  box-shadow: 4px 4px 40px 7px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #fff;
  padding: 32px 24px 0;
  margin-bottom: 32px;

  ${media.xl`
    padding: 50px 50px 12px;
  `}
`;

const Image = styled(Imgix)`
  width: 50px;
  height: auto;
  object-fit: contain;
`;

const Headline = styled.h4`
  font-size: 20px;
  margin-left: 16px;
  margin-bottom: 0;
`;

const HeadlineHighlight = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  margin: 0 4px;
`;

const HorizontalContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`;

const Input = styled.input`
  padding: 8px 14px;
  background: #fff;
  outline: none !important;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 14px;
  width: 100%;
  height: 45px;
  border-radius: 6px;
  font-family: Lato;

  &:focus {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ error, theme }) =>
    error &&
    `
    border-color: ${theme.palette.error.main} !important;
  `}
`;

const FormContainer = styled.div`
  width: 100%;
`;

const Notice = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: #000;
  margin-bottom: 0;
  cursor: pointer;
  font-family: Lato;
`;

const SignupButton = styled(LoadingButton)`
  margin-bottom: 16px;
  margin-top: 18px;
  padding: 12px;
  width: 100%;
  height: auto;
`;

const AfterSubmitContainer = styled.div`
  text-align: center;

  p {
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }
`;

const FormControlCheckbox = styled(FormControl)`
  margin-bottom: 4px;
`;

const StyledFormControl = styled(FormControl)`
  margin-bottom: 22px;
`;

const CheckboxTextHighlight = styled(Link)`
  margin: 0 5px;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
`;

const getSubmitURL = (formId) =>
  `https://api.hsforms.com/submissions/v3/integration/submit/5223531/${formId}`;

const FooterNewsLetterSubscription = ({
  image,
  emailLabel,
  headlineHighlight,
  headlinePostHighlight,
  headlinePreHighlight,
  marketingNotice,
  privacyNoticeHighlight,
  privacyNoticePreHighlight,
  privacyNoticePostHighlight,
  submitButtonText,
  hubspotFormId,
  privacyNoticeLink,
  newsletterSuccessMessage,
  newsletterErrorMessage,
}) => {
  const { register, handleSubmit, errors, formState, setValue, getValues } =
    useForm();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = async (values) => {
    if (success) return;
    try {
      await axios.post(getSubmitURL(hubspotFormId?.text), {
        ...valuesToHubspotBody({
          values,
          privacyText: `${privacyNoticePreHighlight?.text} ${privacyNoticeHighlight?.text} ${privacyNoticePostHighlight?.text}`,
          marketingText: marketingNotice?.text,
        }),
      });

      setSuccess(true);
    } catch (err) {
      console.error(err);
      setError(true);
    }
  };

  const handleAccept = (evt, fieldName) => {
    if (evt?.target?.tagName === 'A') return;
    setValue(fieldName, !getValues(fieldName));
  };

  return (
    <NewsletterContainer>
      <Container>
        <Row>
          <HorizontalContainer>
            {image?.url && (
              <Image
                className="lazyload"
                src={image?.url}
                sizes="100vw"
                attributeConfig={{
                  src: 'data-src',
                  srcSet: 'data-srcset',
                  sizes: 'data-sizes',
                }}
              />
            )}
            <Headline>
              {headlinePreHighlight?.text}
              <HeadlineHighlight>{headlineHighlight?.text}</HeadlineHighlight>
              {headlinePostHighlight?.text}
            </Headline>
          </HorizontalContainer>
        </Row>
        <Row>
          <FormContainer>
            {error && (
              <AfterSubmitContainer>
                <p>{newsletterErrorMessage?.text}</p>
              </AfterSubmitContainer>
            )}
            {success && (
              <AfterSubmitContainer>
                <p>{newsletterSuccessMessage?.text}</p>
              </AfterSubmitContainer>
            )}
            {!error && !success && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col col={12}>
                    <StyledFormControl>
                      <Input
                        ref={register({
                          required: 'Email is required',
                          pattern: {
                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            message: 'Please enter a valid email',
                          },
                        })}
                        error={errors.email}
                        type="email"
                        name="email"
                        placeholder={emailLabel?.text}
                      />
                      {errors.email && (
                        <ErrorMessage>{errors.email.message}</ErrorMessage>
                      )}
                    </StyledFormControl>
                  </Col>
                  <Col col={12}>
                    <FormControlCheckbox type="checkbox">
                      <input
                        name="privacyAccepted"
                        ref={register({
                          required: 'Please agree to our privacy notice',
                        })}
                        type="checkbox"
                      />
                      <Notice
                        onClick={(e) => handleAccept(e, 'privacyAccepted')}
                      >
                        {privacyNoticePreHighlight?.text}
                        <CheckboxTextHighlight to={privacyNoticeLink?.url}>
                          {privacyNoticeHighlight?.text}
                        </CheckboxTextHighlight>
                        {privacyNoticePostHighlight?.text}
                      </Notice>
                    </FormControlCheckbox>
                  </Col>
                  <Col col={12}>
                    <FormControlCheckbox type="checkbox">
                      <input
                        name="marketingAccepted"
                        ref={register({
                          required: 'Please accept to receive regular news',
                        })}
                        type="checkbox"
                      />
                      <Notice
                        onClick={(e) => handleAccept(e, 'marketingAccepted')}
                      >
                        {marketingNotice?.text}
                      </Notice>
                      {(errors.marketingAccepted || errors.privacyAccepted) && (
                        <ErrorMessage>
                          Please accept our privacy notice and that you want to
                          receive regular news
                        </ErrorMessage>
                      )}
                    </FormControlCheckbox>
                  </Col>
                  <Col col={12}>
                    <SignupButton
                      loading={formState.isSubmitting}
                      type="submit"
                      className="plausible-event-name=Newsletter+Signup+Submit"
                    >
                      {submitButtonText?.text}
                    </SignupButton>
                  </Col>
                </Row>
              </form>
            )}
          </FormContainer>
        </Row>
      </Container>
    </NewsletterContainer>
  );
};

export default FooterNewsLetterSubscription;

const valuesToHubspotBody = ({ values, privacyText, marketingText }) => {
  return {
    fields: [
      {
        name: 'email',
        value: values.email,
      },
    ],
    context: {
      pageUri: window.location.pathname,
      pageName: 'seedtrace website',
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: values.privacyAccepted,
        text: privacyText,
        communications: [
          {
            value: values.marketingAccepted,
            subscriptionTypeId: 999,
            text: marketingText,
          },
        ],
      },
    },
  };
};
